import { CuratedNewsLayout } from '@common/clients/api';

import styles from './FeaturedCarrousel.module.scss';

export const CONTAINER_CLASS_NAMES: Record<CuratedNewsLayout, string> = {
    [CuratedNewsLayout.EXPANDED_FOOTER]: styles['ef-container'],
    [CuratedNewsLayout.EXPANDED_SIDE]: styles['es-container'],
    [CuratedNewsLayout.MINIFIED_SIDE]: styles['ms-container'],
    [CuratedNewsLayout.SINGLE_MAIN]: styles['es-container'],
    [CuratedNewsLayout.TWIN_MAINS]: styles['tm-container'],
};
