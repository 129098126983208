import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { range } from 'lodash';

import { CuratedNewsItem, CuratedNewsLayout } from '@common/clients/api';
import { LAYOUT_ITEM_COUNT, LEGACY_ITEM_COUNT_DIFF } from '@common/types/LayoutItemCount';
import { DefaultDynamicSizes } from '@web/atoms/WebpImage';
import { MediaQuery, MediaQueryDirection, useResponsive } from '@web/hooks/useResponsive/useResponsive';

import { getItemClassName } from './helpers/getItemClassName';
import { CONTAINER_CLASS_NAMES } from './constants';
import { FeaturedCarrouselItem, ItemLayout } from './FeaturedCarrouselItem';

import styles from './FeaturedCarrousel.module.scss';

export interface Props {
    featuredItems: CuratedNewsItem[];
    isEditable?: boolean;
    desktopLayout?: CuratedNewsLayout;
    responsiveLayout?: CuratedNewsLayout;
    legacyLayout?: boolean;
}

const scrollToItem = (index: number, carrouselRef: RefObject<HTMLUListElement>) => {
    if (!carrouselRef.current) return;
    const rect = carrouselRef.current.getBoundingClientRect();
    const width = rect.right - rect.left;
    carrouselRef.current.scrollTo(index * width, 0);
};

const getItemsPosition = (firstFeaturedElementRef?: RefObject<HTMLLIElement>) => {
    if (!firstFeaturedElementRef?.current) return 0;
    return firstFeaturedElementRef.current.getBoundingClientRect().left || 0;
};

const getImageSizes = (layout: CuratedNewsLayout, index: number): string => {
    switch (layout) {
        case CuratedNewsLayout.EXPANDED_SIDE:
            switch (index) {
                case 0:
                    return DefaultDynamicSizes.TwoThird;
                default:
                    return DefaultDynamicSizes.OneThird;
            }
        default:
            return '100vw';
    }
};

const getFeaturedItemCount = (layout: CuratedNewsLayout, legacyLayout: boolean): number => {
    const baseCount = LAYOUT_ITEM_COUNT[layout];

    // TODO: When we migrated all platforms to Curation we can remove legacyLayout.
    if (legacyLayout) {
        const legacyDiff = LEGACY_ITEM_COUNT_DIFF[layout] ?? 0;
        return baseCount + legacyDiff;
    }

    return baseCount;
};

export const FeaturedCarrousel = ({
    featuredItems,
    isEditable,
    desktopLayout = CuratedNewsLayout.EXPANDED_SIDE,
    responsiveLayout = CuratedNewsLayout.EXPANDED_SIDE,
    legacyLayout = false,
}: Props): JSX.Element => {
    const isResponsiveMode = useResponsive({ direction: MediaQueryDirection.below, size: MediaQuery.m });

    const layout = useMemo(
        () => (isResponsiveMode ? responsiveLayout : desktopLayout),
        [desktopLayout, isResponsiveMode, responsiveLayout],
    );

    const [activeElement, setActiveElement] = useState<number>(0);

    const carrouselRef = useRef<HTMLUListElement>(null);
    const firstFeaturedElementRef = useRef<HTMLLIElement>(null);

    const itemsToDisplay = useMemo(() => {
        const totalItems = getFeaturedItemCount(layout, legacyLayout);
        return featuredItems?.slice(0, totalItems);
    }, [featuredItems, layout, legacyLayout]);

    useEffect(() => {
        let swipStartPosition = getItemsPosition(firstFeaturedElementRef);
        const diffMargin = 20;

        carrouselRef.current?.addEventListener('scroll', () => {
            // Don't add event listener on big screens.
            const isMiniView =
                carrouselRef.current &&
                firstFeaturedElementRef?.current &&
                carrouselRef.current.offsetWidth < carrouselRef.current.scrollWidth;

            if (isMiniView) {
                const swipeCurrentPosition = getItemsPosition(firstFeaturedElementRef);
                const swipingDiff: number = swipStartPosition - swipeCurrentPosition;

                // In order to improve the performance, evaluate the active featured-item every 20px of swiping
                if (Math.abs(swipingDiff) > diffMargin) {
                    const firstRec = firstFeaturedElementRef?.current?.getBoundingClientRect();
                    if (firstRec) {
                        const width = firstRec.right - firstRec.left;
                        const leftPosition = firstRec?.left;
                        const currentIndex = Math.round(leftPosition / -width);
                        swipStartPosition = getItemsPosition(firstFeaturedElementRef);
                        setActiveElement(currentIndex);
                    }
                }
            }
        });
    }, []);

    const hasScrollingIndicator = useMemo(
        () => [CuratedNewsLayout.EXPANDED_SIDE, CuratedNewsLayout.MINIFIED_SIDE].includes(layout),
        [layout],
    );

    const rootStyle = layout === CuratedNewsLayout.EXPANDED_FOOTER ? { height: '60vw' } : undefined;
    return (
        <aside className={styles.FeaturedCarrousel} style={rootStyle}>
            <ul ref={carrouselRef} className={CONTAINER_CLASS_NAMES[layout]}>
                {layout !== CuratedNewsLayout.MINIFIED_SIDE ? (
                    <>
                        {itemsToDisplay?.map((item, index) => {
                            const itemLayout =
                                index !== 0 && layout === CuratedNewsLayout.EXPANDED_FOOTER
                                    ? ItemLayout.FOOTER
                                    : ItemLayout.EXPANDED;

                            return (
                                <FeaturedCarrouselItem
                                    key={`featuredItem${index}`}
                                    index={index}
                                    featuredItem={item}
                                    className={getItemClassName(layout, index)}
                                    itemRef={index === 0 ? firstFeaturedElementRef : undefined}
                                    layout={itemLayout}
                                    isEditable={isEditable}
                                    imageSizes={getImageSizes(layout, index)}
                                />
                            );
                        })}
                    </>
                ) : null}

                {layout === CuratedNewsLayout.MINIFIED_SIDE ? (
                    <>
                        <FeaturedCarrouselItem
                            key={'featuredItem0'}
                            index={0}
                            featuredItem={itemsToDisplay[0]}
                            className={getItemClassName(layout, 0)}
                            itemRef={firstFeaturedElementRef}
                            isEditable={isEditable}
                            layout={ItemLayout.EXPANDED_WITH_SIDE}
                            imageSizes={getImageSizes(layout, 0)}
                        />

                        <hr className="vertical"></hr>

                        <div className={styles['minified-side-container']}>
                            {itemsToDisplay?.map((item, index) => {
                                if (index !== 0) {
                                    return (
                                        <FeaturedCarrouselItem
                                            key={`featuredItem${index}`}
                                            index={index}
                                            featuredItem={item}
                                            isEditable={isEditable}
                                            className={getItemClassName(layout, index)}
                                            layout={ItemLayout.MINIFIED}
                                            imageSizes={getImageSizes(layout, index)}
                                        />
                                    );
                                }
                                return null;
                            })}
                        </div>

                        {itemsToDisplay?.map((item, index) => {
                            if (index !== 0) {
                                return (
                                    <FeaturedCarrouselItem
                                        key={`featuredItem${index}`}
                                        index={index}
                                        featuredItem={item}
                                        className={`${getItemClassName(layout, index)} ${
                                            styles['responsive-only']
                                        }`}
                                        layout={ItemLayout.EXPANDED_WITH_SIDE}
                                        isEditable={isEditable}
                                        imageSizes={getImageSizes(layout, index)}
                                    />
                                );
                            }
                            return null;
                        })}
                    </>
                ) : null}
            </ul>

            {hasScrollingIndicator ? (
                <ul className={styles['slider-bar']}>
                    {range(0, itemsToDisplay.length).map((index) => {
                        const indicatorClassName =
                            index === activeElement
                                ? `${styles.indicator} ${styles.active}`
                                : styles.indicator;

                        return (
                            <li
                                id={`indicator${index}`}
                                key={`indicator${index}`}
                                onClick={() => {
                                    scrollToItem(index, carrouselRef);
                                }}
                            >
                                <span className={indicatorClassName} />
                            </li>
                        );
                    })}
                </ul>
            ) : null}
        </aside>
    );
};
