import { CuratedNewsLayout } from '@common/clients/api';

import styles from '../FeaturedCarrousel.module.scss';

export const getItemClassName = (layout: CuratedNewsLayout, index: number): string => {
    const classNames: string[] = [];

    switch (layout) {
        case CuratedNewsLayout.EXPANDED_SIDE:
            classNames[0] = styles.xxlarge;
            classNames[1] = `${styles.medium} ${styles['upper-item']}`;
            classNames[2] = styles.medium;
            break;

        case CuratedNewsLayout.MINIFIED_SIDE:
            classNames[0] = styles.full;
            classNames[1] = styles.xsmall;
            classNames[2] = styles.xsmall;
            classNames[3] = styles.xsmall;
            classNames[4] = styles.xsmall;
            break;

        case CuratedNewsLayout.SINGLE_MAIN:
            classNames[0] = styles.full;
            break;

        case CuratedNewsLayout.TWIN_MAINS:
            classNames[0] = styles.large;
            classNames[1] = styles.large;
            break;

        case CuratedNewsLayout.EXPANDED_FOOTER:
            classNames[0] = styles.xlarge;
            classNames[1] = styles.small;
            classNames[2] = styles.small;
            classNames[3] = styles.small;
            break;
    }

    return `${styles['responsive-article']} ${classNames[index] || ''}`;
};
