import {
    FC,
    FormEvent,
    KeyboardEventHandler,
    memo,
    RefObject,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { useTranslation } from '@cms/i18n';
import { Icon } from '@common/atoms/Icon';
import { Link } from '@common/atoms/Link';
import { CuratedNewsFontSize as FontSize, CuratedNewsItem, PlatformID } from '@common/clients/api';
import { WebpWidthEnum } from '@common/types/WebpWidth';
import { useContextData } from '@common/useContextData';
import { TagElement } from '@web/atoms/TagElement';
import { WebpImage } from '@web/atoms/WebpImage';

import ActionSection from './ActionSection';

import styles from './FeaturedCarrousel.module.scss';

export enum ItemLayout {
    MINIFIED = 'minified',
    EXPANDED = 'expanded',
    EXPANDED_WITH_SIDE = 'expanded_with_side',
    FOOTER = 'footer',
}

export interface Props {
    index: number;
    featuredItem: CuratedNewsItem;
    className: string;
    layout?: ItemLayout;
    itemRef?: RefObject<HTMLLIElement>;
    isEditable?: boolean;
    imageSizes?: string;
}

const fontSizes = {
    increase: {
        [FontSize.S]: FontSize.M,
        [FontSize.M]: FontSize.L,
        [FontSize.L]: FontSize.XL,
        [FontSize.XL]: FontSize.XXL,
        [FontSize.XXL]: FontSize.XXXL,
        [FontSize.XXXL]: FontSize.XXXL,
    },
    decrease: {
        [FontSize.S]: FontSize.S,
        [FontSize.M]: FontSize.S,
        [FontSize.L]: FontSize.M,
        [FontSize.XL]: FontSize.L,
        [FontSize.XXL]: FontSize.XL,
        [FontSize.XXXL]: FontSize.XXL,
    },
};

const Component: FC<Props> = ({
    itemRef,
    index,
    className,
    featuredItem,
    isEditable,
    layout = ItemLayout.EXPANDED,
    imageSizes,
}) => {
    const __components = useTranslation('components').t;
    const titleRef = useRef<HTMLHeadingElement>(null);
    const contextData = useContextData();
    const newsItem = featuredItem.newsItem;

    const [isEditModeActive, setEditModeStatus] = useState(false);
    const [isSaveTitleDisabled, setSaveTitleStatus] = useState(false);
    const [fontSize, setFontSize] = useState<FontSize>(featuredItem.fontSize);

    useEffect(() => {
        if (titleRef.current) {
            titleRef.current.innerText = featuredItem.title || featuredItem.newsItem.newsTitle || '';
        }
    }, [titleRef, featuredItem.title, featuredItem.newsItem.newsTitle]);

    useEffect(() => {
        if (featuredItem.fontSize) {
            setFontSize(featuredItem.fontSize);
        }
    }, [featuredItem.fontSize]);

    const itemHref = useMemo(
        () =>
            featuredItem.newsItem.path
                ? featuredItem.newsItem.path
                : `${featuredItem.newsItem.path}/${featuredItem.newsItem.newsID}/${featuredItem.newsItem.mainTagSlug}`,
        [featuredItem.newsItem],
    );

    const initialTitle = useMemo(
        () => featuredItem.title || newsItem.newsTitle || '',
        [featuredItem.title, newsItem.newsTitle],
    );

    const hasComments = useMemo(() => Number(newsItem.commentCount) > 0, [newsItem.commentCount]);

    const onSaveChanges = () => {
        if (!titleRef.current) return;

        setEditModeStatus(false);
        // TODO: call the update news article endpoint after it's implemented [PB-3973]
    };

    const onClose = () => {
        if (titleRef.current) {
            titleRef.current.innerText = initialTitle;
        }

        setFontSize(featuredItem.fontSize || FontSize.XL);
        setEditModeStatus(false);
    };

    const onInputChange = (event: FormEvent<HTMLHeadingElement>) => {
        const newTitle = event.currentTarget.textContent || '';
        setSaveTitleStatus(newTitle.length === 0);

        if (titleRef.current) {
            titleRef.current.innerText = newTitle;
        }
    };

    const onKeyChange: KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key === 'Enter') {
            onSaveChanges();
        }
    };

    const onFontIncrease = () => {
        setFontSize(fontSizes.increase[fontSize]);
    };

    const onFontDecrease = () => {
        setFontSize(fontSizes.decrease[fontSize]);
    };

    const ItemContent: FC = () => {
        const hasMinifiedLayout = layout === ItemLayout.MINIFIED;
        const hasExpandedLayout = layout === ItemLayout.EXPANDED_WITH_SIDE || layout === ItemLayout.EXPANDED;
        const hasFooterLayout = layout === ItemLayout.FOOTER;

        const platformsWithoutComments = [PlatformID.VI];

        const hasReactions =
            !hasMinifiedLayout && hasComments && !platformsWithoutComments.includes(contextData.platform.id);

        const fontSizeClassName = hasExpandedLayout
            ? styles[`size-${fontSize}`]
            : styles['responsive-content-text'];

        const commentsClassName =
            layout === ItemLayout.FOOTER
                ? `${styles.comments} ${styles['footer-comments']}`
                : styles.comments;

        const rootClassName = hasMinifiedLayout
            ? styles['minified-article-content']
            : hasFooterLayout
              ? styles['footer-article-content']
              : '';

        let infoClassName;

        switch (layout) {
            case ItemLayout.EXPANDED:
                infoClassName = `${styles.info} ${styles['expanded-info']} inverted`;
                break;

            case ItemLayout.EXPANDED_WITH_SIDE:
                infoClassName = `${styles.info} ${styles['expanded-info']} ${styles['expanded-when-minified-title']} inverted`;
                break;

            case ItemLayout.FOOTER:
                infoClassName = `${styles.info} ${styles['footer-info']}`;
                break;

            case ItemLayout.MINIFIED:
                infoClassName = `${styles.info} ${styles['minified-info']} inverted`;
                break;
        }

        const hasTag =
            hasExpandedLayout && (contextData.platform.id !== PlatformID.BR || newsItem.newsSubTitle);

        return (
            <div className={rootClassName}>
                {hasExpandedLayout ? (
                    <figure
                        className={layout === ItemLayout.EXPANDED_WITH_SIDE ? styles['double-gradient'] : ''}
                    >
                        <WebpImage
                            defaultSize={WebpWidthEnum.WIDTH_450}
                            src={newsItem?.image || ''}
                            fill={true}
                            sizes={imageSizes}
                            alt=""
                            priority={true}
                        />
                    </figure>
                ) : null}
                {hasMinifiedLayout ? (
                    <div className={styles['minified-article-image']}>
                        <WebpImage
                            defaultSize={WebpWidthEnum.WIDTH_450}
                            ratio={16 / 9}
                            src={newsItem?.image || ''}
                            sizes={imageSizes}
                            alt=""
                            priority={true}
                        />
                    </div>
                ) : null}
                {hasFooterLayout ? (
                    <div className={styles['footer-article-image']}>
                        <WebpImage
                            defaultSize={WebpWidthEnum.WIDTH_450}
                            src={newsItem?.image || ''}
                            fill={true}
                            sizes={imageSizes}
                            alt=""
                            priority={true}
                        />
                    </div>
                ) : null}
                <div className={infoClassName}>
                    {hasTag ? <TagElement item={newsItem} allowSubtitle={true} /> : null}
                    {isEditModeActive ? (
                        <h2 className={`${styles['edit-input']} ${fontSizeClassName}`}>
                            <span
                                className={styles['title-background']}
                                data-content={titleRef.current?.innerText || initialTitle}
                            ></span>
                            <span
                                ref={titleRef}
                                onChange={onInputChange}
                                onKeyDown={onKeyChange}
                                contentEditable="true"
                                suppressContentEditableWarning
                            >
                                {titleRef.current?.innerText || initialTitle}
                            </span>
                        </h2>
                    ) : null}
                    {!isEditModeActive ? (
                        <h2 className={fontSizeClassName}>
                            <span
                                className={styles['title-background']}
                                data-content={titleRef.current?.innerText || initialTitle}
                            ></span>
                            <span>{titleRef.current?.innerText || initialTitle}</span>
                        </h2>
                    ) : null}
                    {hasReactions ? (
                        <div className={commentsClassName}>
                            <Icon.comment />
                            {__components('featuredCarrousel.reactions', {
                                count: newsItem.commentCount,
                            })}
                        </div>
                    ) : null}
                </div>
            </div>
        );
    };

    return (
        <li className={className} key={`featuredItem${index}`} id={`featuredItem${index}`} ref={itemRef}>
            <article>
                {isEditable ? (
                    <ActionSection
                        isEditModeActive={isEditModeActive}
                        isFontIncreaseDisabled={fontSize === FontSize.XXXL}
                        isFontDecreaseDisabled={fontSize === FontSize.S}
                        isSaveTitleDisabled={isSaveTitleDisabled}
                        onCancel={onClose}
                        onFontIncrease={onFontIncrease}
                        onFontDecrease={onFontDecrease}
                        onSave={onSaveChanges}
                        onEdit={() => setEditModeStatus(true)}
                    />
                ) : null}
                {isEditModeActive ? (
                    <>
                        <div className={styles.overlay} />
                        <ItemContent />
                    </>
                ) : (
                    <Link href={itemHref}>
                        <ItemContent />
                    </Link>
                )}
            </article>
        </li>
    );
};

export const FeaturedCarrouselItem = memo(Component);
