import { CuratedNewsLayout } from '@common/clients/api';

export const LAYOUT_ITEM_COUNT: Record<CuratedNewsLayout, number> = {
    [CuratedNewsLayout.EXPANDED_SIDE]: 4,
    [CuratedNewsLayout.MINIFIED_SIDE]: 3,
    [CuratedNewsLayout.TWIN_MAINS]: 2,
    [CuratedNewsLayout.EXPANDED_FOOTER]: 4,
    [CuratedNewsLayout.SINGLE_MAIN]: 1,
};

// TODO: When we migrated all platforms to Curation we can remove legacyLayout. This is the difference of number of items in the legacy layout.
// compared to the newer LAYOUT_ITEM_COUNT for curation
export const LEGACY_ITEM_COUNT_DIFF: Partial<Record<CuratedNewsLayout, number>> = {
    [CuratedNewsLayout.MINIFIED_SIDE]: 2,
    [CuratedNewsLayout.EXPANDED_SIDE]: -1,
};
